@import "colors";

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

.extra-small {
  font-size: 0.8em;
}

.large {
  font-size: 1.1em;
}

.extra-large {
  font-size: 1.5em;
}

h1 span {
  color: $sub-title;
  font-size: 20px;
  font-weight: 400;
}

h2 span {
  color: $sub-title;
  font-size: 18px;
  font-weight: 400;
}

h3 span {
  color: $sub-title;
  font-size: 16px;
  font-weight: 400;
}

h4 span {
  color: $sub-title;
  font-size: 14px;
  font-weight: 400;
}

td h5 {
  display: inline-block;
}

/*--------------------------------------------------------------
# Login
--------------------------------------------------------------*/
.hero-section {
  background: linear-gradient(
      to right,
      rgba(39, 70, 133, 0.8) 0%,
      rgba(61, 179, 197, 0.8) 100%
    ),
    url(https://allstage.co/assets/img/hero-bg.jpg);
  position: relative;
}

.login-text-button {
  width: 256px;
}

/*--------------------------------------------------------------
# Spacing
--------------------------------------------------------------*/

.margin-right-10 {
  margin-right: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.card-body-small {
  padding: 7px 20px 10px;
}

/*--------------------------------------------------------------
# Badges
--------------------------------------------------------------*/

.badge-recently-viewed {
  color: $main-link-hover-color;
  background: $main-background;
  font-weight: 500;
  margin-right: 10px;
  &:hover {
    color: $main-link-color;
    text-decoration: none;
  }
}

.rounded-circle {
  border: solid 2px $main-background;
}

.badge-thin {
  padding-left: 5px;
  padding-right: 5px;
}

/*--------------------------------------------------------------
# Tables
--------------------------------------------------------------*/
@mixin width {
  width: auto !important;
}

.table {
  font-size: 0.8em;
  .table {
    font-size: 1em;
  }
  .success {
    background-color: $bg-success-light;
  }
  .primary {
    background-color: $bg-info-light;
  }
  .danger {
    background-color: $bg-danger-light;
  }
  ul.list-unstyled {
    margin-bottom: 0;
    li {
      border-bottom: 1px dotted white;
      padding: 5px 0 5px 5px;
    }
    :last-child {
      border-bottom: none;
    }
  }
  tbody {
    .no-margin {
      td {
        margin: 0;
        padding: 0;
      }
    }
    td label {
      font-size: 1em;
    }
  }
  img.logo {
    max-width: 80px;
    max-height: 80px;
  }
  img.profile {
    max-width: 36px;
    max-height: 36px;
  }
  td.table-fit-20,
  th.table-fit-20 {
    white-space: nowrap;
    width: 20%;
  }
  td.table-fit-15,
  th.table-fit-15 {
    white-space: nowrap;
    width: 15%;
  }
  td.table-fit-10,
  th.table-fit-10 {
    white-space: nowrap;
    width: 10%;
  }
  td.table-fit,
  th.table-fit {
    white-space: nowrap;
    width: 1%;
  }
}

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
.hiddenRow {
  padding: 0 !important;
}

.copyright {
  padding: 0 10px;
}

.title-bar {
  button {
    float: right;
    margin-top: 16px;
  }
}

@media (min-width: 1600px) {
  .modal-xl {
    --bs-modal-width: 1540px;
  }
}

.card-header {
  .card-title.inline-block {
    padding: 0;
    display: inline-block;
    .alert-badge {
      color: white;
      font-size: 0.5em;
      left: 10px;
    }
  }
}

.card-scroll-100 {
  max-height: 160px;
  overflow-y: auto;
}

.card-scroll-300 {
  max-height: 360px;
  overflow-y: auto;
}

.card {
  label {
    font-weight: 600;
  }
}

.required:after {
  content: " *";
  color: $tertiary;
}

.form-row i.form-info {
  font-size: 14px;
  top: -4px;
  position: relative;
  color: $main-link-color;
}

.popover {
  background-color: $main-background;
}

/*--------------------------------------------------------------
# Modal
--------------------------------------------------------------*/
.modal-header {
  .form-switch {
    margin: 2px 0 0 20px;
    label {
      font-size: 0.75em;
      text-transform: uppercase;
      top: -2px;
      position: relative;
    }
  }
  .edit {
    bottom: -3px;
    position: relative;
    margin-left: 5px;
  }
  .modal-logo {
    max-height: 50px;
    max-width: 50px;
    margin-right: 14px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.profilePicBorder,
.founderAttributeBorder,
.founderAttributeBorderSmall {
  border: 1px solid #bbbbbb;
  border-radius: 100%;
  display: inline-block;
}
.profilePic,
.founderAttribute {
  border-radius: 50%;
  margin: 2px;
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/

.col-form-label {
  padding-top: 0;
  font-weight: 600;
  XXXfont-size: 1.15em;
  font-size: inherit;}

.col-sm-8.col-form-label,
.card .col-form-label {
  padding-top: 0;
  font-weight: 500;
  font-size: 1em;
}

.form-select {
  width: auto;
}

.form-row {
  padding-top: 10px;
  padding-bottom: 10px;
  i {
    font-size: 1.7em;
  }
  .badge-recently-viewed {
    font-size: 1.15em;
  }
}

#borderedTabContent {
  .form-row {
    border-bottom: 1px solid $main-background;
  }
}

.company-attributes {
  .bi-check2-circle {
    color: $success;
    font-size: 1.5em;
  }
  .bi-circle,
  .bi-x-circle {
    font-size: 1.25em;
    padding-left: 1px;
    color: $bg-secondary-light;
  }
  .bi-x-circle {
    color: $tertiary;
  }
  td {
    .bi-check2-circle {
      font-size: 1.25em;
    }
    .bi-circle,
    .bi-x-circle {
      font-size: 1.05em;
    }
  }
  div.row.form-row:nth-of-type(odd) {
    background: $main-background;
  }
  .col-form-label {
    padding: 6px 10px;
  }
  .form-row {
    margin-bottom: 0;
    padding: 0;
  }
}
.zebra div.row:nth-of-type(odd) {
  background: $main-background;
}

/*--------------------------------------------------------------
# Demo Ribbon
--------------------------------------------------------------*/

.timeline_area {
  position: relative;
  z-index: 1;
}

.single-timeline-area {
  position: relative;
  z-index: 1;
  padding-left: 180px;
}

@media only screen and (max-width: 575px) {
  .single-timeline-area {
    padding-left: 100px;
  }
}

.single-timeline-area .timeline-date {
  position: absolute;
  width: 180px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 60px;
}

@media only screen and (max-width: 575px) {
  .single-timeline-area .timeline-date {
    width: 100px;
  }
}

.single-timeline-area .timeline-date::after {
  position: absolute;
  width: 3px;
  height: 100%;
  content: "";
  background-color: $main-background;
  top: 0;
  right: 30px;
  z-index: 1;
}

.single-timeline-area .timeline-date::before {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: $primary;
  content: "";
  top: 50%;
  right: 26px;
  z-index: 5;
  margin-top: -5.5px;
}

.single-timeline-area .timeline-date p {
  margin-bottom: 0;
  color: #020710;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
}

.single-timeline-area .single-timeline-content {
  position: relative;
  z-index: 1;
  padding: 10px 10px 3px;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-top: 15px;
  -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  border: 1px solid $bg-primary-light;
}

@media only screen and (max-width: 575px) {
  .single-timeline-area .single-timeline-content {
    padding: 20px;
  }
}

.single-timeline-area .single-timeline-content .timeline-icon {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 30px;
  height: 30px;
  background-color: $primary;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  text-align: center;
  max-width: 30px;
  border-radius: 50%;
  margin-right: 15px;
}

.single-timeline-area .single-timeline-content .timeline-icon i {
  color: #ffffff;
  line-height: 30px;
}

.single-timeline-area .single-timeline-content .timeline-text h6 {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-timeline-area .single-timeline-content .timeline-text p {
  font-size: 13px;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Demo Ribbon
--------------------------------------------------------------*/
.ribbon-box {
  position: relative;
}
.ribbon {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: fixed;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: $tertiary;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -38px;
  bottom: 30px;
  transform: rotate(-45deg);
}

/*--------------------------------------------------------------
# Delete these classes, they are just for the demo
--------------------------------------------------------------*/
